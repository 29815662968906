/* Casestudydetails.css */

.casestudy-section {
    padding: 2rem 1rem;
}

.case-study-detail {
    display: flex;
    flex-direction: column; /* Stack elements on small screens */
    margin: 1rem 0;
}

.case-study-title {
    font-size: 1.5rem; /* Adjust as needed */
    margin-bottom: 1rem;
}

.case-study-image {
    max-width: 100%; /* Responsive image */
    height: auto; /* Maintain aspect ratio */
    margin-bottom: 1rem; /* Space between image and text */
}

.case-study-description {
    font-size: 1rem; /* Adjust text size */
}

/* Media Queries for larger screens */
@media (min-width: 768px) {
    .case-study-detail {
        flex-direction: row; /* Horizontal layout on larger screens */
        align-items: flex-start; /* Align items at the start */
    }

    .case-study-image {
        width: 40%; /* Adjust as needed for larger screens */
        margin-right: 2rem; /* Space between image and description */
    }

    .case-study-description {
        width: 55%; /* Adjust as needed for larger screens */
    }
}
