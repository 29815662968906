.events-calendar {
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.react-calendar {
  width: 100%;
  max-width: 800px;
  font-size: 1.2em;
}

.event-marker {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.event-marker.has-event .dot {
  width: 8px;
  height: 8px;
  background-color: red;
  border-radius: 50%;
  margin: auto;
}

.event-details-popup {
  position: absolute;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 16px;
  z-index: 1000;
  width: 250px;
  transition: opacity 0.2s ease, transform 0.2s ease;
  pointer-events: none;
  opacity: 1;
  /* Default margins for larger screens */
  margin-left: -51rem;
  margin-top: -8rem;
}

.popup-content {
  display: flex;
  flex-direction: column;
}

.popup-header {
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 8px;
}

.popup-title {
  font-size: 18px;
  margin: 0;
  color: #333;
}

.popup-date {
  font-size: 14px;
  color: #888;
}

.popup-body {
  display: flex;
  align-items: center;
}

.popup-icon {
  width: 20px;
  margin-right: 8px;
}

.popup-entertainment {
  font-size: 16px;
  color: #555;
  margin: 0;
}

.popup-time {
  font-size: 14px;
  color: #444;
}

.popup-link {
  font-size: 14px;
  color: #007bff;
  text-decoration: none;
  margin-top: 5px;
}

.popup-link:hover {
  text-decoration: underline;
}

@media (max-width: 600px) {
  .react-calendar {
    font-size: 1em;
  }

  .events-calendar {
    max-width: 100%;
  }

  .event-details-popup {
    /* Adjust width, margins, and padding for mobile */
    width: 200px;
    margin-left: -30rem; /* Adjust this to prevent overflow */
    margin-top: -5rem; /* Adjust top positioning */
    padding: 12px;
    font-size: 0.9em;
  }

  .popup-header {
    margin-bottom: 6px; /* Reduce spacing for mobile */
  }

  .popup-title {
    font-size: 16px;
  }

  .popup-entertainment {
    font-size: 14px;
  }

  .popup-time {
    font-size: 12px;
  }

  .popup-link {
    font-size: 12px;
  }

  .popup-icon {
    width: 18px;
    margin-right: 6px;
  }
}

@media (max-width: 400px) {
  .event-details-popup {
    /* Further adjustments for very small screens */
    width: 180px;
    margin-left: -25rem; /* Reduce more if needed */
    margin-top: -4rem;
  }

  .popup-title {
    font-size: 14px;
  }

  .popup-entertainment,
  .popup-time,
  .popup-link {
    font-size: 12px;
  }

  .popup-icon {
    width: 16px;
    margin-right: 4px;
  }
}
