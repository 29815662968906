
  
  .login-box {

   
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .account-sign-in {
    padding: 30px;
    background-color: #fff;
    border-radius: 10px;
    margin-top: 8%;
  }
  
  .title h2 {
    font-size: 24px;
    font-weight: 600;
  }
  
  .login-with h6, .divider h6 {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 20px;
  }
  
  .login-social .col {
    margin-bottom: 10px;
  }
  
  .boxes {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
  }
  
  .boxes:hover {
    background-color: #f8f8f8;
  }
  
  .form-control {
    margin-bottom: 15px;
  }
  
  .button-bottom button {
    margin-top: 15px;
  }
  
  .btn-primary {
 
    border: none;
  }
  
  .btn-outline-secondary {
    border: 2px solid #007bff;
    color: #007bff;
  }
  
  .btn-outline-secondary:hover {
    background-color: #007bff;
    color: white;
  }
  

  