.video-card {
    height: 100%; /* Make the card full height */
}

.video-thumbnail iframe {
    border-radius: 0.5rem; /* Rounded corners */
}

.manual-section .manual-card {
    display: flex;
    justify-content: center; /* Center the manual card */
    margin-top: 20px; /* Add some space at the top */
}

.manual-section .card {
    width: 100%; /* Use full width for better responsiveness */
    max-width: 250px; /* Limit max width for large screens */
}

.manual-link {
    text-decoration: none;
    color: #333;
    transition: color 0.3s, transform 0.3s; /* Add transform for hover */
}

.manual-link:hover {
    color: #007bff; /* Change color on hover */
    transform: scale(1.05); /* Slightly enlarge on hover */
}

.section-head h4 {
    margin-bottom: 15px; /* Add space below the title */
}

.section-description {
    margin-bottom: 30px; /* Add space below the description */
}
