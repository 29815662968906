.custom-arrow {
  border: none;
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.custom-arrow.slick-prev {
  left: 10px;
  z-index: 1;
}

.custom-arrow.slick-next {
  right: 29px;
  z-index: 1;
}


@media (max-width: 768px) {
  .custom-arrow {
    font-size: 14px;
    padding: 8px 16px;
  }

  .custom-arrow.slick-prev {
    left: 5px;
  }

  .custom-arrow.slick-next {
    right: 5px;
  }
}