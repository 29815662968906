.blog-details {
    background-color: #fff;
    overflow: hidden;
}

.blog-title {
    font-size: 2.5rem;
    margin-bottom: 15px;
    color: #333;
    text-align: center;
}

.blog-meta {
    color: #777;
    margin-bottom: 20px;
    font-size: 1rem;
    text-align: left;
}

.blog-image {
    max-width: 100%;
    height: auto;
    margin: 20px 0;
    border-radius: 10px;
    transition: transform 0.3s;
}

.blog-image:hover {
    transform: scale(1.05);
}

.blog-body {
    display: flex;
    flex-direction: row;
    /* Change to row for side-by-side layout */
    gap: 20px;
    
}

.blog-image {
    flex: 1;
    /* Allow the image to grow */
    max-width: 100%;
    /* Maintain image responsiveness */
    border-radius: 10px;
    transition: transform 0.3s;
}

.blog-text {
    flex: 2 1;
    line-height: 8.8;
    color: #444;
    margin-top: 20px;
}

@media (max-width: 600px) {
    .blog-body {
        flex-direction: column;
        /* Stack vertically on small screens */
    }
}



.comments-section {
    margin-top: 40px;
    padding: 20px;
    background-color: #f9f9f9;
    border-top: 2px solid #e1e1e1;
}

.comments-title {
    font-size: 1.8rem;
    margin-bottom: 15px;
    color: #333;
}

.comments-list {
    list-style: none;
    padding: 0;
}

.comment-item {
    margin-bottom: 15px;
    padding: 10px;
    border-left: 3px solid #007bff;
    background-color: #ffffff;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.comment-item:hover {
    background-color: #f0f8ff;
}

.comment-author {
    font-weight: bold;
    color: #007bff;
}

.comment-text {
    margin-top: 5px;
    color: #555;
}

/* Responsive adjustments */
@media (max-width: 600px) {
    .blog-body {
        flex-direction: column;
        align-items: stretch;
    }
}