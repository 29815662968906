.bank-detail-container {
    padding: 20px;
    max-width: 100%;
    margin: 0 auto;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .bank-detail-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    font-weight: bold;
  }
  
  input[type="text"],
  input[type="file"] {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box; 
    text-align: left; 
  }
  
  .radio-group {
    display: flex;
    gap: 15px; 
  }
  
  .update-container {
    display: flex;
    align-items: center; 
    margin-top: 15px; 
  }
  
  .cheque-preview {
    margin-left: 15px; 
  }
  
  .cheque-preview img {
    width: 80px; 
    height: auto;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .update-button {
    padding: 10px;
    background: rgb(4, 47, 114);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
  }
  
  .update-button:hover {
    background-color: #0056b3;
  }
  