.card-body {
    padding: 20px; 
    text-align: left; 
}

.pricing-details {
    margin-bottom: 15px; 
}

.pricing-details p {
    margin: 5px 0; 
    font-size: 16px; 
}

.features-list {
    list-style-type: none; 
    padding-left: 0; 
    margin: 10px 0; 
}

.features-list li {
    font-size: 16px;
    margin-bottom: 8px; 
    line-height: 1.5; 
}

.card-text.feature {
    margin-bottom: 15px; 
}

.card-text.feature strong {
    font-size: 18px; 
    display: block;
    margin-bottom: 10px; 
}

.card-text p {
    font-size: 16px; 
    margin-top: 10px; 
}

.card-text p strong {
    font-weight: bold; 
}

.card-img-top.fixed-height {
    height: 200px; 
    object-fit: cover; 
    width: 100%; 
}

