/* General Styles */
.task-manager {
    font-family: 'Arial', sans-serif;
    background-color: #f3f3f3;
    border-radius: 10px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    max-width: 1200px;
    margin: 0 auto;
}

.task-manager__title {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #2c3e50;
}

/* Layout: Container with sidebar */
.task-manager__layout {
    display: flex;
    gap: 20px;
    flex-wrap: wrap; /* Allows wrap for smaller screens */
}

/* Sidebar for Calendar */
.task-manager__sidebar {
    flex: 1;
    max-width: 327px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.task-manager__sidebar h2 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #2980b9;
}

/* Main Content: Task Cards */
.task-manager__content {
    flex: 3;
    padding: 13px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.task-manager__content h2 {
    /* font-size: 1.8rem; */
    color: #2c3e50;
    margin-bottom: 20px;
}

/* Task Cards */
.task-manager__cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); /* Responsive grid */
    gap: 20px; /* Space between cards */
}

/* Task Card Styles */
.task-manager__card {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative; /* For absolute positioning of status */
    overflow: hidden; /* For rounded corners on hover */
}

.task-manager__card:hover {
    transform: translateY(-5px); /* Lift effect on hover */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Status and Description */
.task-manager__status {
    font-weight: bold;
    color: #555;
}

.task-manager__description {
    margin-top: 10px;
    color: #777;
    line-height: 1.4;
}

/* Completed and Pending Task Cards */
.task-manager__card.completed {
    background-color: #d4edda;
    border-left: 5px solid #28a745;
}

.task-manager__card.pending {
    background-color: #f8d7da;
    border-left: 5px solid #dc3545;
}

/* Modal Styling */
.task-manager__modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.task-manager__modal-content {
    max-width: 500px;
    padding: 30px;
  }

/* Buttons */
.task-manager__done-btn,
.task-manager__close-btn,
.task-manager__submit-reply-btn {
    padding: 10px 20px;
    margin: 10px;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.task-manager__done-btn {
    background-color: #4caf50;
    color: white;
}

.task-manager__done-btn:hover {
    background-color: #45a049;
}

.task-manager__close-btn {
    background-color: #f44336;
    color: white;
}

.task-manager__close-btn:hover {
    background-color: #e53935;
}

.task-manager__submit-reply-btn {
    background-color: #2196f3;
    color: white;
}

.task-manager__submit-reply-btn:hover {
    background-color: #1976d2;
}

/* Button Container */
.task-manager__button-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .task-manager__layout {
        flex-direction: column;
    }

    .task-manager__modal-content {
        max-width: 90%;
        width: 100%;
    }

    .task-manager__card {
        flex-direction: row; /* Adjust layout for smaller screens */
        gap: 10px;
    }
}

/* Reply Section */
.task-manager__reply-section {
    margin-top: 20px;
}

.task-manager__reply-section textarea {
    width: 100%;
    height: 60px;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.task-manager__reply-display {
    margin-top: 10px;
    font-style: italic;
    color: #555;
}

.task-manager__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.task-manager__text h3 {
    font-size: 1.5rem; /* Larger title */
    margin: 0;
    color: #2c3e50;
}

.task-manager__description {
    font-size: 0.95rem;
    color: #555;
    margin-top: 0.5rem;
    line-height: 1.4;
}
  
  /* Status styling for clear differentiation */
  .task-manager__status {
    position: absolute; /* Position at the top right */
    top: 15px;
    right: 15px;
    padding: 5px 10px;
    border-radius: 20px; /* Pill shape */
    font-size: 0.85rem;
    font-weight: bold;
}

.status-completed {
    background-color: #28a745;
    color: white;
}

.status-pending {
    background-color: #dc3545;
    color: white;
}

  
@media (max-width: 768px) {
    .task-manager__card {
        padding: 15px; /* Reduce padding for smaller screens */
    }

    .task-manager__header h3 {
        font-size: 1.3rem; /* Slightly smaller titles */
    }

    .task-manager__description {
        font-size: 0.9rem; /* Adjust description font size */
    }
}
  
  @media (max-width: 480px) {
    .task-manager__card {
      width: 100%; /* One card per row for mobile */
    }
  }


@media (max-width: 992px) {
    .task-manager__card {
      width: calc(50% - 1.5rem); /* 2 cards per row for medium screens */
    }
  }
  
  @media (max-width: 600px) {
    .task-manager__card {
      width: 100%; /* 1 card per row for mobile */
    }
  }

/* Style for task-assigned dates */
.task-assigned {
    background-color: #ffcccb; /* Light red background for task-assigned dates */
    border-radius: 50%;
    color: white;
    font-weight: bold;
  }
  
  /* Style for the current date */
  .current-date {
    background-color: #87ceeb; /* Light blue background for current date */
    border-radius: 50%;
    color: white;
    font-weight: bold;
  }
  
  .task-manager__header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.task-manager__history-button {
    background: rgb(4, 47, 114);
  color: white;
  border: none;
  padding: 0.5em 1em;
  cursor: pointer;
  border-radius: 4px;
}

.task-manager__history-button:hover {
  background-color: #0056b3; /* Darker shade for hover effect */
}
