.customer-support-section {
    padding: 40px 20px;
    background-color: #f9f9f9;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
}

h1, h2 {
    text-align: center;
    color: #333;
    margin-bottom: 30px;
}

.support-details {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.support-form, .support-info {
    width: 48%;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.support-form h2, .support-info h2 {
    font-size: 24px;
    margin-bottom: 20px;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    color: #555;
}

.form-group input, .form-group textarea {
    
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
}

.submit-btn {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-btn:hover {
    background-color: #0056b3;
}

.faq-section {
    margin-top: 40px;
}

.faq-item {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.faq-item h3 {
    font-size: 18px;
    margin-bottom: 10px;
    color: #333;
}

.faq-item p {
    color: #555;
}

@media (max-width: 768px) {
    .support-details {
        flex-direction: column;
    }

    .support-form, .support-info {
        width: 100%;
        margin-bottom: 20px;
    }
}
