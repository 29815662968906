/* Ourstory.css */

.container {
    max-width: 1200px;
    margin: 0 auto;
    
}



.intro-card, .info-card {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 40px;
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.intro-image, .info-image {
    width: 100%;
    max-width: 600px;
    height: auto;
    border-radius: 8px;
    margin-bottom: 20px;
}

.intro-content, .info-content {
    flex: 1;
    padding: 20px;
}

.section-heading {
    font-size: 24px;
    margin-bottom: 15px;
    color: #333;
}

p, ul {
    font-size: 18px;
    line-height: 1.6;
   
}

/* ul {
    padding-left: 20px;
} */

li {
    margin-bottom: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .intro-card, .info-card {
        flex-direction: column;
        text-align: center;
    }

    .intro-content, .info-content {
        padding: 0;
    }

    .info-image {
        margin-bottom: 20px;
    }

    .section-heading {
        font-size: 20px;
    }

    p, ul {
        font-size: 16px;
    }
}

@media (max-width: 576px) {
    .section-heading {
        font-size: 18px;
    }

    p, ul {
        font-size: 14px;
    }
}
