.user-guide-section {
    padding: 40px 20px;
    background-color: #f9f9f9;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
}

h1, h2 {
    text-align: center;
    color: #333;
    margin-bottom: 30px;
}

.manual-section, .tutorial-section, .faq-section {
    margin-bottom: 40px;
}

.manual-section p {
    text-align: center;
    color: #555;
    margin-bottom: 20px;
}

.download-btn {
    display: block;
    width: 200px;
    margin: 0 auto;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.download-btn:hover {
    background-color: #0056b3;
}

/* Video Tutorials Section */
.tutorial-section .tutorial-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.tutorial-item {
    width: 32%;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    text-align: center;
}

.tutorial-item h3 {
    font-size: 18px;
    margin-bottom: 10px;
    color: #333;
}

.tutorial-item iframe {
    width: 100%;
    height: 200px;
    border: none;
    border-radius: 8px;
}

/* FAQ Section */
.faq-section .faq-item {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.faq-item h3 {
    font-size: 18px;
    margin-bottom: 10px;
    color: #333;
}

.faq-item p {
    color: #555;
}

@media (max-width: 768px) {
    .tutorial-item {
        width: 100%;
        margin-bottom: 20px;
    }
}

.pdf-card {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin-top: 20px;
    background-color: #f9f9f9;
}

.pdf-icon {
    width: 40px; /* Adjust size as needed */
    height: 40px; /* Adjust size as needed */
    margin-right: 15px;
}
