.contact-hero {
  background-color: #f8f9fa;
  background-color: #f8f9fa;
  text-align: center;
  
  align-items: center; /* Vertically centers the content */
  justify-content: center; /* Horizontally centers the content */


}

.hero-content {
  max-width: 800px;
  margin: 0 auto;
}

.section-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 30px;
}

.section-subtitle {
  font-size: 32px;
  margin-bottom: 20px;
}

.section-description {
  font-size: 18px;
  color: #6c757d;
  margin-top: 35px!important;
  
}

.contact-details-form {
  padding: 60px 0;
}

.contact-details {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: 100%;
}

.contact-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.contact-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.contact-icon {
  font-size: 24px;
  color: #2f2d9e;
  margin-right: 15px;
}

.contact-info p {
  margin: 0;
}

.contact-info a {
  color: #2f2d9e;
  text-decoration: none;
}

.contact-form {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.form-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.form-control {
  border-radius: 4px;
  border: 1px solid #ced4da;
  padding: 10px;
  text-align: left;
}

.btn-primary {
  background: rgb(4, 47, 114);
  border-color: #2f2d9e;
  border-radius: 4px;
  color: #fff;
}