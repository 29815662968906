* {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
}

body {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    line-height: 1.6;
}

.container {
    max-width: 970px;
    margin: 0 auto;
    text-align: left;
}

.faq-heading {
    margin-top: 45px;
    margin-bottom: 15px;
    text-align: center;
    font-weight: 400;
    font-size: 30px;
}

.faq-card {
    border: 1px solid #dce0e8;
    border-radius: 6px;
    padding: 24px 20px;
    margin-bottom: 12px;
    list-style: none;
    position: relative;
    cursor: pointer;
}

.faq-open-icon {
    font-size: 22px;
    position: absolute;
    top: 18px;
    right: 24px;
    font-weight: 700;
}

details[open] .faq-open-icon {
    top: 18px;
    right: 24px;
}

details>summary {
    list-style: none;
    text-align: left;
}

details>summary::-webkit-details-marker {
    display: none;
}

details[open] summary {
    color: #2f2d9e;
    font-weight: 500;
}

details[open] .faq-card-spoiler {
    display: inline-block;
    padding-top: 8px;
    color: #8b8b9a;
}

.faq-aftertext {
    text-align: center;
    color: #8b8b9a;
}