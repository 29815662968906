.terms-of-service {
    padding: 20px;
    max-width: 800px;
    margin: auto;
  }
  
  .terms-of-service h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .terms-of-service h2 {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .terms-of-service p {
    font-size: 16px;
    line-height: 1.6;
  }
  
  .terms-of-service a {
    color: #007bff;
    text-decoration: none;
  }
  
  .terms-of-service a:hover {
    text-decoration: underline;
  }
  

  