.attendance-container {
  display: flex;
  flex-direction: column;
  min-height: 49vh;
  font-family: Arial, sans-serif;
  color: #333;
}

.attendance-header {
  background: rgb(4, 47, 114);
  color: white;
  padding: 20px;
  text-align: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.attendance-content {
  flex: 1;
  padding: 20px;
  background-color: #f9f9f9;
}

.attendance-records {
  margin-top: 9rem;
  padding: 15px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

h2 {
  color: #2f2d9e;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

th,
td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: center;
  /* Changed to center for both th and td */
}

th {
  background-color: #f2f2f2;
  color: black;
}

tr:hover {
  background-color: #f1f1f1;
}

@media (max-width: 768px) {
  .attendance-container {
    padding: 10px;
  }

  .attendance-header h1 {
    font-size: 24px;
  }

  .attendance-content {
    padding: 10px;
  }

  th,
  td {
    padding: 10px;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;

  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}

.modal-content button {
  margin-top: 20px;
}

/* Align text to the left in modal table cells */
.modal-content table {
  width: 100%;
  /* Make sure the table takes the full width */
}

.modal-content td {
  /* Align text to the left */
}

.view-details-button {
  background: rgb(4, 47, 114);
  /* Blue background */
  color: white;
  /* White text */
  border: none;
  /* No border */
  border-radius: 4px;
  /* Rounded corners */
  padding: 10px 15px;
  /* Vertical and horizontal padding */
  font-size: 16px;
  /* Font size */
  cursor: pointer;
  /* Pointer cursor on hover */
  transition: background-color 0.3s, transform 0.2s;
  /* Smooth transitions */
}

.view-details-button:hover {
  background-color: #0056b3;
  /* Darker blue on hover */
  transform: translateY(-2px);
  /* Slight lift on hover */
}

.view-details-button:focus {
  outline: none;
  /* Remove focus outline */
}

.view-details-button:active {
  transform: translateY(1px);
  /* Slight depression on click */
}






.table-responsive {
  overflow-x: auto;
  /* Enables horizontal scrolling */
  -webkit-overflow-scrolling: touch;
  /* Smooth scrolling for mobile devices */
}

.table-responsive table {
  width: 100%;
  /* Ensures the table takes full width */
  border-collapse: collapse;
  /* Optional: collapse borders */
}

.table-responsive th,
.table-responsive td {
  padding: 8px;
  /* Add some padding for better spacing */
  border: 1px solid #ddd;
  /* Optional: add borders */
}

.table-responsive th {
  background-color: #f2f2f2;
  /* Optional: add a background color to headers */
}


.modal-content {
  padding: 20px;
  /* Add padding for better spacing */
  border-radius: 8px;
  /* Optional: round the corners */
  background-color: #fff;
  /* Ensure a white background for contrast */
}

.table-responsive {
  overflow-x: auto;
  /* Enables horizontal scrolling */
  -webkit-overflow-scrolling: touch;
  /* Smooth scrolling for mobile devices */
}

.attendance-detail-table {
  width: 100%;
  /* Ensures the table takes full width */
  border-collapse: collapse;
  /* Collapse borders */
}

.attendance-detail-table th,
.attendance-detail-table td {
  padding: 8px;
  /* Add some padding for better spacing */
  text-align: left;
  /* Align text to the left */
  border: 1px solid #ddd;
  /* Optional: add borders */
}

.attendance-detail-table strong {
  font-weight: bold;
  /* Ensure strong elements are bold */
}


.attendance-modal {
  width: 50%;
  /* Default width for larger screens */

  margin: auto;
  /* Center the modal */
}

@media (max-width: 768px) {

  /* Adjust for smaller screens */
  .attendance-modal {
    width: 80%;
    /* Width for mobile devices */
  }
}

@media (max-width: 480px) {

  /* Further adjust for extra small screens */
  .attendance-modal {
    width: 95%;
    /* Width for very small devices */
  }
}


.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
  /* Space between elements */
}

.pagination-button {
  background: rgb(4, 47, 114);
  /* Bootstrap primary color */
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.pagination-button:disabled {
  background-color: #ccc;
  /* Light gray for disabled */
  cursor: not-allowed;
}

.pagination-button:hover:not(:disabled) {
  background-color: #0056b3;
  /* Darker shade on hover */
}

.pagination-text {
  margin: 0 10px;
  font-size: 16px;
}