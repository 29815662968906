.section-b-space {
    position: relative;
    z-index: 2;
    padding: 60px 0;
}

.background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    overflow: hidden;
}

.background-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.overlay {
    position: relative;
    z-index: 2;
 
}

.animation-section {
    position: relative;
    z-index: 3;
}


.account-sign-in {
    background-color: #fff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
}

.account-sign-in .title h3 {
    margin-bottom: 20px;
}

.btn-solid {
    background-color: #007bff;
    color: white;
    border: none;
}

.btn-outline {
    border: 2px solid #007bff;
    color: #007bff;
    background: transparent;
}

.divider {
    text-align: center;
    margin: 20px 0;
    position: relative;
}

.divider h6 {
    background-color: #fff;
    display: inline-block;
    padding: 0 10px;
    position: relative;
    z-index: 2;
}

.divider:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #ccc;
    z-index: 1;
}