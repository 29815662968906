/* Faq Container */
.faq-container {
    max-width: 800px;
    margin: 70px auto;
    padding: 40px 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.faq-container h1 {
    text-align: center;
    font-size: 32px;
    margin-bottom: 30px;
    color: #333;
}

/* FAQ Item */
.faq-item {
    background-color: #fff;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 15px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.faq-item.open {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* FAQ Question */
.faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    color: #333;
}

.faq-question h3 {
    margin: 0;
}

.faq-question span {
    font-size: 24px;
    color: #007bff;
}

/* FAQ Answer */
.faq-answer {
    margin-top: 15px;
    font-size: 16px;
    color: #555;
    line-height: 1.5;
    border-top: 1px solid #ddd;
    padding-top: 15px;
    display: block; /* Change to block so it's visible when FAQ is open */
}

/* Responsive Design */
@media (max-width: 768px) {
    .faq-container {
        padding: 20px;
    }

    .faq-container h1 {
        font-size: 28px;
    }

    .faq-question h3 {
        font-size: 16px;
    }

    .faq-answer {
        font-size: 14px;
    }
}
