.dashboard-intro {
    padding: 2rem;
    background-color: #f0f2f5;
    border-radius: 10px;
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .dashboard-title {
    font-size: 1.8rem;
    font-weight: 700;
    color: #333;
  }
  
  .dashboard-title span {
    color: #007bff;
  }
  
  .dashboard-description {
    font-size: 1rem;
    color: #666;
    margin-bottom: 1.5rem;
  }
  
  .complete-profile .complete-box {
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s ease;
  }
  
  .complete-profile .complete-box:hover {
    transform: translateY(-5px);
  }
  
  .complete-profile .card-body {
    padding: 2rem;
  }
  
  .complete-profile .icon-complete {
    font-size: 2rem;
    color: #28a745;
    margin-bottom: 0.5rem;
  }
  
  .complete-profile .icon-incomplete {
    font-size: 2rem;
    color: #dc3545;
    margin-bottom: 0.5rem;
  }
  
  .complete-profile .card-title {
    font-size: 1.2rem;
    font-weight: 600;
    color: #343a40;
  }
  
  .complete-box.not-complete {
    background-color: #fff5f5;
    border: 1px solid #f5c6cb;
  }
  
  @media (max-width: 767px) {
    .dashboard-intro {
      padding: 1rem;
    }
  
    .complete-profile .card-body {
      padding: 1.5rem;
    }
  }
  

  .activity-box {
    padding: 15px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.activity-box h6 {
    font-size: 18px;
    margin-bottom: 15px;
    color: #333;
}

.activity-list {
    list-style: none;
    padding-left: 0;
    margin: 0;
}

.activity-list li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #eee;
}

.activity-list li i {
    margin-right: 10px;
    color: #007bff;
}

.activity-time {
    font-size: 12px;
    color: #999;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .activity-box {
        padding: 10px;
    }
    
    .activity-box h6 {
        font-size: 16px;
    }
    
    .activity-list li {
        flex-direction: column;
        align-items: flex-start;
    }

    .activity-list li i {
        margin-bottom: 5px;
    }

    .activity-time {
        margin-top: 5px;
    }
}

@media (max-width: 576px) {
    .activity-box h6 {
        font-size: 14px;
    }

    .activity-list li {
        padding: 8px 0;
    }

    .activity-time {
        font-size: 10px;
    }
}

.profile-image img {
  width: 150px; /* Adjust the size as per your requirement */
  height: 150px;
  border-radius: 50%; /* Makes the image circular */
  object-fit: cover; /* Ensures the image covers the entire area */
  border: 2px solid #ccc; /* Optional: Adds a border around the circle */
}

.profile-edit {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: white;
  padding: 5px;
  border-radius: 50%;
}



.table-responsive {
  overflow-x: auto;
}

@media (max-width: 768px) {
  .table-responsive table {
      width: 100%; /* Make sure the table takes full width on small screens */
  }
  .table-responsive th, .table-responsive td {
      font-size: 14px; /* Adjust font size for better readability */
  }
}

