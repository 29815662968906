.helpcenter-page {
    padding: 60px 20px;
    background-color: #f9f9f9;
}

.helpcenter-header {
    text-align: center;
    margin-bottom: 40px;
}

.helpcenter-header h1 {
    font-size: 36px;
    color: #333;
}

.helpcenter-header p {
    font-size: 18px;
    color: #666;
}

.helpcenter-sections {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
}

.helpcenter-faq, .helpcenter-contact {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.helpcenter-faq h2, .helpcenter-contact h2 {
    font-size: 28px;
    margin-bottom: 20px;
    color: #2a2a2a;
}

.faq-item h3 {
    font-size: 20px;
    margin-bottom: 10px;
    color: #444;
}

.faq-item p {
    font-size: 16px;
    color: #666;
}

.contact-form .form-group {
    margin-bottom: 15px;
}

.contact-form label {
    font-size: 16px;
    color: #333;
}

.contact-form input, .contact-form textarea {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
}

.submit-btn {
    background-color: #007bff;
    color: #fff;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.submit-btn:hover {
    background-color: #0056b3;
}

/* Responsive Design */
@media (max-width: 768px) {
    .helpcenter-sections {
        grid-template-columns: 1fr;
    }

    .helpcenter-header h1 {
        font-size: 28px;
    }
    
    .helpcenter-header p {
        font-size: 16px;
    }
}
