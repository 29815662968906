/* General Styles */
.nav-menu {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-menu li {
  position: relative;
  margin-right: 20px;
}

.nav-menu a {
  text-decoration: none;
  color: #000;
  padding: 10px 15px;
  font-size: 16px;
}

/* Desktop View Styles */
@media (min-width: 769px) {
  .nav-menu {
    display: flex;
  }

  .nav-menu li:hover>.nav-submenu,
  .nav-submenu:hover {
    display: block;
    opacity: 1;
    visibility: visible;
  }

  .nav-submenu {
    position: absolute;
    left: 0;
    top: 89%;
    background: white;
    z-index: 1001;
    padding: 0;
    list-style: none;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    
  }

  .nav-submenu li a {
    padding: 10px 15px;
    display: block;
    color: #000;
  }

  .nav-submenu li a:hover {
    background-color: #f1f1f1;
  }
}

/* Mobile View Styles */
@media (max-width: 768px) {
  .nav-menu {
    flex-direction: column;
    align-items: flex-start;
    display: none;
    /* Initially hide menu */
  }

  .nav-menu.open {
    display: block;
    position: absolute;
    top: 60px;
    /* Adjust according to header height */
    left: 0;
    background: white;
    width: 100%;
    z-index: 1000;
  }

  .nav-menu li {
    border-bottom: 1px solid #ddd;
    width: 100%;
    /* Full width for each item */
  }

  .nav-menu li a {
    padding: 15px;
    display: block;
  }

  .toggle-nav {
    display: block;
    cursor: pointer;
    margin-top: -5rem;
  }

  .nav-submenu {
    display: none;
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    visibility: hidden;
    transition: max-height 0.3s ease, opacity 0.3s ease, visibility 0.3s ease;
    
  }

  .nav-submenu.show {
    display: block;
    max-height: 300px;
    /* Adjust max-height according to your content */
    opacity: 1;
    visibility: visible;
  }

  .nav-link.menu-title i.fa-angle-down {
    float: right;
  }
}

/* Brand Logo Adjustments */
@media only screen and (max-width: 767px) {
  .brand-logo {
    margin-left: -153px;
    margin-top: -7px;
  }
}

/* Menu Overlay */
.menu-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

/* User Dropdown Styles */
.user-dropdown {
  position: relative;
}

.user-dropdown .user-menu {


  display: none;
  position: absolute;
  top: 100%;
  right: -65px;
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 5px;
  z-index: 1000;
  width: 235px;
}


.user-dropdown:hover .user-menu {
  display: block;
}

.user-icon {
  font-size: 24px;
  cursor: pointer;
  color: #000;
  margin-right: 10px;
}

.user-dropdown .user-menu li {
  list-style: none;
  padding: 5px 10px;
}

.user-dropdown:hover .user-menu {
  display: block; /* Show the menu on hover */
}

.user-menu li {
  padding: 10px; /* Spacing for menu items */
}

.user-menu li:hover {
  background-color: #f0f0f0; /* Change background on hover */
}

.user-menu {
  display: none; /* Hide the menu by default */
  position: absolute;
  top: 100%; /* Position below the user icon */
  left: 0;
  background: white; /* Background color */
  border: 1px solid #ccc; /* Optional border */
  z-index: 1000; /* Ensure it's above other elements */
}


.user-dropdown .user-menu li a {
  text-decoration: none;
  color: #333;
  font-size: 14px;
  display: block;
  padding: 8px 10px;
}

.user-dropdown .user-menu li:hover {
  background-color: #f1f1f1;
}

/* Adjust user icon color for mobile */
@media (max-width: 768px) {

  .user-icon i,
  .nav-link.menu-title i {
    color: #000 !important;
    margin-left: 20px;
  }

  .user-dropdown .user-menu {
    position: static;
    /* Reset position for mobile */
    display: none;
    /* Ensure it's hidden until activated */
  }

  .user-dropdown:hover .user-menu {
    display: block;
    /* Show menu on hover */
    margin-top: 10px;
    /* Space between icon and menu */
    margin-left: 10px;
  }
}