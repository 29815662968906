.casestudy-section {
    padding: 2rem 0;
  }
  
  .case-study-item {
    overflow: hidden;
    border-radius: 0.5rem; /* Adds rounded corners */
  }
  
  .case-study-overlay {
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  }
  
  /* Media queries for additional adjustments */
  @media (max-width: 576px) {
    .section-head {
      padding: 1rem;
    }
  }
  