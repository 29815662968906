
  
  .section-head h4 span {
    font-weight: normal;
    color: #555;
  }
  
  .section-head h3 {
    font-size: 2rem;
    margin-top: 10px;
  }
  
  .section-head p {
    font-size: 1.125rem;
    line-height: 1.6;
    color: #333;
    margin-top: 10px;
  }
  
  .corevalue-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .corevalue-card img {
    width: 100%;
    height: auto;
  }
  
  .corevalue-content {
    padding: 15px;
  }
  
  .corevalue-content h5 {
    font-size: 1.25rem;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .corevalue-content p {
    font-size: 1rem;
    color: #666;
  }
  